import React, { memo, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';
import { Button } from '@ozlydev/ozly-sand-ui';
import { setCarouselCurrentIndex } from '../../containers/App/actions';

const CarouselWrapper = styled.div`
  .slick-slider {
    display: grid;
    // height: 60vh;

    @media (max-width: 480px) {
      // overflow: hidden;
    }
  }
  .slick-slide {
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 24px;
    border-radius: 8px;
    margin: 0 8px;
  }

  .slick-next, .slick-prev {
    width: 38px;
    height: 38px;
  }

  .slick-next {
    right: -56px;
  }

  .slick-prev {
    left: -56px;
  }

  .slick-next:before, .slick-prev:before {
    content: none;
  }

  .slick-disabled {
    display: none;
  }

  @media (max-width: 480px) {
    .slick-next {
      right: -12px;
    }

    .slick-prev {
      left: -12px;
      z-index: 1;
    }
  }

  ${props => props.$alignLeft && css`
    .slick-track {
      margin-left: 0;
    }
  }`}
`;

const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          content: 'none',
        }}
      >
        <Button onlyicon="ChevronRight" size="small" />
      </div>
    );
  };
  
  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
        style={{
          content: 'none',
        }}
      >
        <Button onlyicon="ChevronLeft" size="small" />
      </div>
    );
};

const Carousel = ({ slides, enableIndex, extraSettings, alignLeft, style, groupIndexes, groupType, groupValue }) => {
    const dispatch = useDispatch();
    const currentSlideIndex = useSelector(state => groupIndexes ? (state.global.carousel.groupIndexes?.[groupType]?.[groupValue]?.currentIndex || 0) : state.global.carousel.currentIndex);
    const settings = useMemo(() => {
      return {
        dots: false,
        infinite: false,
        initialSlide: currentSlideIndex,
        // fade: true,
        
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToScroll: 1,
              slidesToShow: 1,
            },
          },
        ],
        ...extraSettings,
      };
    }, []);
  
    return (
      <CarouselWrapper $alignLeft={alignLeft} style={style}>
        <Slider {...settings} beforeChange={(currentSlide, nextSlide) => {
          if (!enableIndex) return;

          dispatch(setCarouselCurrentIndex(nextSlide, groupIndexes, groupType, groupValue ));
        }}>
          {slides.map((slide, index) => (
            <div key={index}>
              {slide}
            </div>
          ))}
        </Slider>
      </CarouselWrapper>
    );
  };

  Carousel.defaultProps = {
    groupIndexes: false,
    groupType: 'search',
    groupValue: '',
  };

  export default memo(Carousel);