import React, { memo } from 'react';
import { Chip, ChipContainer } from '@ozlydev/ozly-sand-ui';

const JobBadges = ({ job, justifyContent, size }) => {
    return (
      <ChipContainer justifyContent={justifyContent} size={size}>
        <Chip leftIcon="Geoloc" mr="8" gutter="small" size={size}>{job?.city}</Chip>
        {job?.contract_type === 'CDI' && <Chip leftIcon="Briefcase" gutter="small" size={size}>CDI</Chip>}
        {job?.contract_type === 'CDD' && <Chip leftIcon="Briefcase" gutter="small" size={size}>CDD</Chip>}
        {job?.contract_type === 'INTERN' && <Chip leftIcon="Briefcase" gutter="small" size={size}>Stage</Chip>}
        {job?.contract_type === 'LIB' && <Chip leftIcon="Briefcase" gutter="small" size={size}>Freelance</Chip>}
        {job?.contract_type === 'APPRENTICE' && <Chip leftIcon="Briefcase" gutter="small" size={size}>Alternance</Chip>}
        {job?.contract_type === 'APPRENTICE_PRO' && <Chip leftIcon="Briefcase" gutter="small" size={size}>Contrat Pro</Chip>}
        {job?.contract_type === 'CTT' && <Chip leftIcon="Briefcase" gutter="small" size={size}>Interim</Chip>}
        {job?.contract_type === 'VIE' && <Chip leftIcon="Briefcase" gutter="small" size={size}>VIE</Chip>}
        {job?.contract_type === 'CUI' && <Chip leftIcon="Briefcase" gutter="small" size={size}>CUI</Chip>}
        {job?.contract_type === 'CAE' && <Chip leftIcon="Briefcase" gutter="small" size={size}>CAE</Chip>}
        {job?.contract_type === 'CIE' && <Chip leftIcon="Briefcase" gutter="small" size={size}>CIE</Chip>}
      </ChipContainer>
    );
};

JobBadges.defaultProps = {
    size: 'medium',
    justifyContent: 'flex-start',
};

export default memo(JobBadges);