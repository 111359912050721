import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

import dialogReducer from './redux/dialogs/reducer';
import loaderReducer from './redux/loaders/reducer';
import toastReducer from './redux/toasts/reducer';
import jobsReducer from './redux/jobs/reducer';
import ragElementsReducer from './redux/ragElements/reducer';
import userReducer from './redux/user/reducer';
import alertsReducer from './redux/alerts/reducer';
import likesReducer from './redux/likes/reducer';
import breadcrumbReducer from './redux/breadcrumps/reducer';
import agentsReducer from './redux/agents/reducer';
import chatReducer from './redux/chat/reducer';
import sidebarReducer from './redux/sidebar/reducer';
import feedbacksReducer from './redux/feedbacks/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    dialogs: dialogReducer,
    loaders: loaderReducer,
    toasts: toastReducer,
    jobs: jobsReducer,
    ragElements: ragElementsReducer,
    user: userReducer,
    alerts: alertsReducer,
    likes: likesReducer,
    breadcrumb: breadcrumbReducer,
    chat: chatReducer,
    agents: agentsReducer,
    sidebar: sidebarReducer,
    feedbacks: feedbacksReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
