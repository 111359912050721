import * as types from './types';

export function getAgentsCharacters() {
  return {
    type: [types.GET_AGENTS_CHARACTERS, types.GET_AGENTS_CHARACTERS_SUCCESS, types.GET_AGENTS_CHARACTERS_FAIL],
    baseUrl: `/api/agents/`,
    requestType: 'get',
    loader: 'getAgentsCharacters',
  };
}
