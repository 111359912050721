import * as types from './types';
import { addMessage, streamMessage, invokeChain, getChatHistory } from "./actions";

const chatMiddleware = (store) => (next) => (action) => {
  if (action.type === "SOCKET_MESSAGE_RECEIVED" && action.payload?.action === "stream_langserve_message") {
      store.dispatch(streamMessage(action.payload));
  }

  if (action.type === types.INVOKE_CHAIN && action.chain.name === "chat") {
    store.dispatch(addMessage({
      text: action.payload.body.input.text,
      date: new Date().toISOString(),
      author: "mine"
    }));
  }

  if (action.type === types.INVOKE_CHAIN_SUCCESS && (action?.chain?.toChat || action.chain.name === "chat")) {
    store.dispatch(addMessage({
      text: action.payload.output.content,
      additional_kwargs: action.payload.output.additional_kwargs,
      date: new Date().toISOString(),
    }));

    if (action.payload.output.additional_kwargs?.type === "job_alert" && action.payload.output.additional_kwargs?.action === "create") {
      store.dispatch(invokeChain("chat/job/alert/success", {
        input: {},
      }, true));
    }

    if (action.payload.output.additional_kwargs?.action === "reset_history") {
      store.dispatch({
        type: 'RESET_CHAT_HISTORY_DONE'
      });
      
      store.dispatch(getChatHistory());
    }
  }

  if (action.type === types.GET_CHAT_HISTORY_SUCCESS && action.payload.messages.length === 0) {
      store.dispatch(invokeChain('chat/hello', { input: {} }, true));
  }

  if (action.type === types.RESET_CHAT_HISTORY) {
    store.dispatch(invokeChain('chat/history/reset', { input: {} }, false));
  }

  if (action.type === types.INVOKE_CHAIN_SUCCESS && action.chain.name === "chat/history/reset") {
    store.dispatch({
      type: 'RESET_CHAT_HISTORY_DONE'
    });
    
    store.dispatch(getChatHistory());
  }

  return next(action);
};

export default chatMiddleware;


