import * as types from './types';
import { getAlerts } from './actions';

const alertsMiddleware = (store) => (next) => (action) => {
  const { type } = action;

  if (type === types.ADD_USER_ALERT_SUCCESS || type === types.DELETE_USER_ALERT_SUCCESS) {
    store.dispatch(getAlerts());
  }

  return next(action);
};

export default alertsMiddleware;
