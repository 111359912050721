/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  LOGOUT_SUCCESS,
  RESET_SEARCH,
  SEARCH_JOBS_SUCCESS,
  SEARCH_JOBS_FAIL,
  SEARCH_JOBS_HISTORY_SUCCESS,
  FOCUS_PROMPT,
  SEARCH_MESSAGE,
  SET_CAROUSEL_CURRENT_INDEX,
} from './constants';

import * as jobsTypes from '../../redux/jobs/types';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  currentUser: false,
  user: null,
  currentSearch: null,
  searchHistory: null,
  searchMessages: [],
  promptIsFocus: false,
  initializing: true,
  
  // old but keeep to keep it working for now
  carousel: {
    currentIndex: 0,

    groupIndexes: {
        search: {

        }
    }
  },
};


/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_USER_SUCCESS:
        draft.user = action.payload.user;
        draft.initializing = false;
        // console.log('postMessage REDUCER', action.payload.token, action.payload.user, action);
        window.postMessage(
          { type: 'SESSION_TOKEN', token: action.payload.token, source: 'ozly' },
          '*',
        );
        break;
      case GET_USER_FAIL:
        draft.initializing = false;
        break;

      case SET_CAROUSEL_CURRENT_INDEX:
        if (action.payload?.groupIndexes) {
          draft.carousel.groupIndexes[action.payload.groupType][action.payload.groupValue] = action.payload.index;
        } else {
          draft.carousel.currentIndex = action.payload.index;
        }
        break;

      case jobsTypes.GET_JOBS_BY_SEARCH_ID_SUCCESS: {
        draft.carousel.groupIndexes.search[action.search.id] = 0;
        break;
      }
  
      case SEARCH_JOBS_SUCCESS:
        draft.currentSearch = action.payload;
        draft.carousel.currentIndex = 0;
        draft.promptIsFocus = false;
        break;

      case SEARCH_JOBS_FAIL:
        draft.currentSearch = null;
        break;

      case SEARCH_JOBS_HISTORY_SUCCESS:
        draft.searchHistory = action.payload?.searchHistory;
        break

      case RESET_SEARCH:
        draft.currentSearch = null;
        draft.carousel.currentIndex = 0;
        break;

      case LOGOUT_SUCCESS: {
        draft.user = null;
        draft.currentUser = null;
        break;
      }

      case SEARCH_MESSAGE: {
        draft.searchMessages = draft.searchMessages.push(action.payload.message);
        break;
      }

      case FOCUS_PROMPT: {
        draft.promptIsFocus = typeof action.payload?.value !== 'undefined' ? action.payload?.value : !draft.promptIsFocus;
        break;
      }
    }
  });

export default appReducer;
