import * as types from './types';

export const addDialog = (content, params = {}) => ({
  type: types.ADD_DIALOG,
  payload: { content, params },
});

export const removeDialog = () => ({
    type: types.REMOVE_DIALOG,
});

export const openDialog = (dialogType, params) => ({
  type: types.OPEN_DIALOG,
  payload: { dialogType, params },
});

export const closeDialog = (dialogType) => ({
  type: types.CLOSE_DIALOG,
  payload: { dialogType },
});

export const dialogLoaded = (dialogType, DialogComponent) => ({
  type: types.DIALOG_LOADED,
  payload: { dialogType, DialogComponent },
});

export const resetDialogState = () => ({
  type: types.RESET_DIALOG_STATE,
});
  