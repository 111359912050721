import React from 'react';
import styled from 'styled-components';
import { FloatingButton, Card, Typo, Button, Divider, Chip, Icon, Avatar, Tooltip, ActionsBar } from '@ozlydev/ozly-sand-ui';
import { useDispatch } from 'react-redux';
import { closeSidebar } from '../../../redux/sidebar/actions';
import { invokeChain } from '../../../redux/chat/actions';

const ContainerBottomActionsMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    position: fixed;
    flex-direction: column;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 32px;

    > div {
      background-color: #FFF7F0;
      display: flex;
      align-items: center;
    }
  }
`;


const Template = (data) => {
    const dispatch = useDispatch();

    const bgColor = 'rgb(255, 247, 240)';

    const cond = (label, content = "", html = true) => {
        if (content) {
            return (
                <React.Fragment>
                    <Divider text={label} align="left" bgColor={bgColor} />

                    {html ? (
                        <div dangerouslySetInnerHTML={{
                            __html: content
                        }} />
                    ) : (
                        <div>
                            {content}
                        </div>
                    )}
                </React.Fragment>
            )
        }
    }


    return (
        <Card style={{ maxWidth: 'none', overflow: 'hidden', padding: 0, paddingBottom: 100 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', padding: '32px 0px' }}>
            {!data?.companyLogo && <Icon name="Building" size="xlarge"  gutter="medium" />}
            {data?.companyLogo && <Avatar size="xlarge" src={data?.companyLogo} gutter="medium" />}
            <Typo variant="heading.small" style={{ maxWidth: '70%', textAlign: 'center' }}>
                {data?.title}
            </Typo>
            <div>
                <Chip size="large" style={{ margin: 4 }}>
                    {data?.niveau_etudes?.libelle}
                </Chip>
                <Chip size="large" style={{ margin: 4 }}>
                    {data?.nsf_discipline?.NSF_discipline_libelle}
                </Chip>
            </div>
            {/* <Typo variant="paragraph.medium.small">
                {data?.company}
            </Typo> */}
            {/* {(currentSearch || props.match?.id) && (
                <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    onlyicon="ChevronLeft"
                    size="small"
                    gutter="medium"
                    style={{
                    ...currentSearchRagElementsPage?.index === 0 && {
                        opacity: 0,
                        pointerEvents: 'none',
                    }
                    }}
                    disabled={currentSearchRagElementsPage?.index === 0}
                    onClick={() => {
                    dispatch(getPreviousRagElement(data?._id, props.match?.id ? true : false, 'search', props.match?.id));
                    }}
                    />
                    { !currentSearchRagElementsPage?.isLastPage &&
                    <Button
                    onlyicon="ChevronRight"
                    size="small"
                    gutter="medium"
                    // disabled={currentSearchRagElementsPage?.isLastPage}
                    onClick={() => {
                    dispatch(getNextRagElement(data?._id, props.match?.id ? true : false, 'search', props.match?.id));
                    }}
                />}
                </div>
            )} */}
            </div>

            {cond('Description', data?.descriptif_format_court)}
            {cond('Accès', data?.descriptif_acces)}
            {cond('Attendus', data?.attendus)}
            {cond('Poursuite d\'études', data?.descriptif_poursuite_etudes)}
            {cond('Les métiers visés', data?.metiers_formation?.metier?.map(d => d.libelle.trim()).join('\n'))}
            {cond('Fiche Onisep', <a href={`https://www.onisep.fr/http/redirection/formation/slug/${data?.identifiant}`} target="_blank">{data?.title}</a>, false)}


            <Divider text="Informations supplémentaires" align="left" bgColor={bgColor} />
            <div>
                <a href={data?.url} target="_blank" rel="noreferrer">{data?.url}</a>
            </div>
            <div>
                {data?.sources_numeriques?.length > 0 && data.sources_numeriques?.map(source_url => (
                    <a href={source_url} target="_blank" rel="noreferrer">{source_url}</a>
                ))}
            </div>

            {data?.formationStructures?.length > 0 && (
                <React.Fragment>
                    <Divider text="Les établissement d'enseignement" align="left" bgColor={bgColor} />
                    {data?.formationStructures?.map(structure => (
                        <div>
                            <div style={{marginBottom: 15}}>
                                <Typo variant="paragraph.medium.small">
                                    {structure.lieu_denseignement_ens_libelle}
                                </Typo>
                                <Typo variant="paragraph.medium.small">
                                    {structure.ens_adresse}
                                </Typo>
                                <Typo variant="paragraph.medium.small">
                                    {structure.ens_code_postal} {structure.ens_commune} - {structure.ens_departement}
                                </Typo>
                                <Typo variant="paragraph.medium.small">
                                    {structure.ens_n_telephone}
                                </Typo>
                                <Typo variant="paragraph.medium.small">
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatch(closeSidebar('main'));
                                            dispatch(invokeChain("chat", { input: {
                                                text: `Je veux plus d'informations sur ${structure.lieu_denseignement_ens_libelle}`,
                                                intent: 'SCHOOLRAG',
                                                filter: {
                                                    idStructure: structure.identifiant
                                                }
                                            } } ));
                                        }}
                                        style={{color: '#0078D4', cursor: 'pointer'}}
                                    >
                                        + d'infos sur l'établissement
                                    </div>
                                </Typo>
                            </div>
                        </div>
                    ))}
                </React.Fragment>
            )}
            <ContainerBottomActionsMobile>
                <ActionsBar>
                    <Button
                        size="medium"
                        gutter="none"
                        variant="ghost"
                        onlyicon="Back"
                        onClick={() => {
                            dispatch(closeSidebar('main'));
                        }}
                    />
                </ActionsBar>
            </ContainerBottomActionsMobile>
        </Card>
    )
}

export default Template