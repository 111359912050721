import * as types from './types';
import { getLikeStatus, getLikes } from './actions';

const likesMiddleware = (store) => (next) => (action) => {
  const { type } = action;

  if (type === types.LIKE_ELEMENT_SUCCESS) {
    if (action.like.refreshLikes) {
      store.dispatch(getLikes());
    } 
    
    store.dispatch(getLikeStatus(action.like.id, action.like.type));
  }

  return next(action);
};

export default likesMiddleware;
