export const GET_RAGELEMENT = 'GET_RAGELEMENT';
export const GET_RAGELEMENT_SUCCESS = 'GET_RAGELEMENT_SUCCESS';
export const GET_RAGELEMENT_FAIL = 'GET_RAGELEMENT_FAIL';

export const SET_RAGELEMENT_DATA = 'SET_RAGELEMENT_DATA';

export const GET_NEXT_RAGELEMENT = 'GET_NEXT_RAGELEMENT';
export const GET_NEXT_RAGELEMENT_DONE = 'GET_NEXT_RAGELEMENT_DONE';
export const GET_PREVIOUS_RAGELEMENT = 'GET_PREVIOUS_RAGELEMENT';
export const GET_PREVIOUS_RAGELEMENT_DONE = 'GET_PREVIOUS_RAGELEMENT_DONE';

export const GET_RAGELEMENT_BY_IDS = 'GET_RAGELEMENT_BY_IDS';
export const GET_RAGELEMENT_BY_IDS_SUCCESS = 'GET_RAGELEMENT_BY_IDS_SUCCESS';
export const GET_RAGELEMENT_BY_IDS_FAIL = 'GET_RAGELEMENT_BY_IDS_FAIL';

export const GET_RAGELEMENTS_BY_SEARCH_ID = 'GET_RAGELEMENTS_BY_SEARCH_ID';
export const GET_RAGELEMENTS_BY_SEARCH_ID_SUCCESS = 'GET_RAGELEMENTS_BY_SEARCH_ID_SUCCESS';
export const GET_RAGELEMENTS_BY_SEARCH_ID_FAIL = 'GET_RAGELEMENTS_BY_SEARCH_ID_FAIL';
