import * as types from './types';
import * as globalTypes from '../../containers/App/constants';
import { OPEN_SIDEBAR } from '../sidebar/types';

const initialState = {
    data: {
    },
    similar: {
    },
    currentSearch: {
        index: 0,
        isLastPage: false,
    },
    search: {

    },
    groupIndexes: {
        search: {},
    }
  };
  
  const jobsReducer = (state = initialState, action) => {
    switch (action.type) {
      case OPEN_SIDEBAR: {

        if (action.payload?.metadata?.show !== 'job') {
            return state;
        }

        const jobId = action.payload.metadata.job._id;
        const searchId = action.payload.metadata.search._id;
        const currentResults = state?.search?.[searchId]?.results || [];
        const currentIndex = currentResults?.findIndex(job => job._id === jobId) || 0;

        return {
            ...state,
            groupIndexes: {
                ...state.groupIndexes,
                search: {
                    ...state.groupIndexes?.search,
                    [searchId]: {
                        index: currentIndex,
                        isLastPage: currentIndex >= currentResults.length - 1,
                    },
                },
            } 
        }
      }


      case types.GET_JOBS_BY_SEARCH_ID_SUCCESS: {
        const results = action.payload.results;
        const newJobs = {};

        results?.forEach((job) => {
            newJobs[job._id] = job;
        });

        return {
            ...state,
            data: {
                ...state.data,
                ...newJobs,
            },
            search: {
                ...state.search,
                [action.search.id]: {
                    results,
                },
            },
            groupIndexes: {
                    ...state?.groupIndexes,
                    search: {
                        ...state?.groupIndexes?.search,
                        [action.search.id]: {
                            index: 0,
                            isLastPage: false,
                        },
                    },
                },
            };
        }
    
      case types.GET_JOB_SUCCESS: {
            return {
            ...state,
                data: {
                    ...state.data,
                    [action.payload.job._id]: action.payload.job,
                },
                errors: {
                    ...state.errors,
                    [action.payload.job._id]: null,
                },
            };
        }

        case types.GET_JOB_FAIL: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.jobs.jobId]: true,
                },
            };
        }

        case types.GET_NEXT_JOB_DONE:
        case types.GET_PREVIOUS_JOB_DONE: {
            if (action.payload?.groupIndexes) {
                return {
                    ...state,
                    groupIndexes: {
                        ...state.groupIndexes,
                        [action.payload.groupType]: {
                            ...state.groupIndexes?.[action.payload.groupType],
                            [action.payload.groupValue]: {
                                index: action.payload.index,
                                isLastPage: action.payload.isLastPage,
                            },
                        },
                    } 
                };
            }

            return { // single search (need to be removed later)
                ...state,
                currentSearch: action.payload,
            };
        }

        case types.GET_JOB_SIMILAR_SUCCESS: {
            const results = action.payload.results;
            const newJobs = {};

            results?.forEach((job) => {
                newJobs[job._id] = job;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    ...newJobs,
                },
                similar: {
                    [action.jobs.jobId]: {
                        ...state.similar[action.jobs.jobId],
                        [action.jobs.similarType]: results,
                    },
                },
            };
        }

        case globalTypes.SEARCH_JOBS_SUCCESS: {
            const results = action.payload.results;
            const newJobs = {};

            results?.forEach((job) => {
                newJobs[job._id] = job;
            });

            return {
                ...state,
                data: {
                    ...state.data,
                    ...newJobs,
                },
            };
        }

        case types.SET_JOB_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data._id]: action.data,
                },
            };
        }
      default:
        return state;
    }
  };
  
  export default jobsReducer;
  