import produce from 'immer';
import * as types from './types';

export const initialState = {
  data: null,
};

const alertsReducer = (state = initialState, action) =>
  produce(state, () => {
    switch (action.type) {
      case types.GET_USER_ALERTS_SUCCESS:
        return {
          ...state,
          data: action.payload.alerts,
        };
      
        break;
    }
  });

export default alertsReducer;
