/**
 *
 * JobPage
 *
 */

import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useDrag } from '@use-gesture/react';
import { getJob } from '../../redux/jobs/actions';
import makeSelectJobPage from './selectors';
import { FloatingButton, Card, Typo, Button, Divider, Chip, Icon, Avatar, Tooltip, ActionsBar } from '@ozlydev/ozly-sand-ui';
import { resetSearch } from '../../containers/App/actions';
import { openDialog } from '../../redux/dialogs/actions';
import { getNextJob, getPreviousJob } from '../../redux/jobs/actions';
import { sendEventAnalytics } from '../../redux/analytics/actions';
import { likeElement } from '../../redux/likes/actions';
import { closeSidebar } from '../../redux/sidebar/actions';
import JobBadges from '../../components/Job/Badges';
import JobCarouselSimilar from '../../components/Job/CarouselSimilar';
import Like from '../../components/Like';

const ContainerBottomRightActions = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 64px;
  bottom: 64px;
  align-items: flex-end;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ContainerBottomActionsMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    position: fixed;
    flex-direction: column;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 32px;

    > div {
      background-color: #FFF7F0;
      display: flex;
      align-items: center;
    }
  }
`;

const ContainerTopCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 152px;

  @media (max-width: 768px) {
    margin-top: 112px;
  }
`;

const ContainerContent = styled.div`
    > div {
      padding: 32px;
      margin-bottom: 32px;
    }

    @media (max-width: 768px) {
      > div {
        padding: 32px 8px;
        padding-bottom: 128px;
      }
    }
`;

function formatText(text) {
  const strippedText = text.replace(/<[^>]*>?/gm, '');
  const cleanedText = strippedText.replace(/\n/g, ' ').trim();
  const formattedText = cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1).toLowerCase();

  return formattedText;
}

function entitiesText({ labelType, job, type }) {
  const filteredData = job?.entities.filter(item => item.label === labelType);
  if (type === 'chip') {
    return (<div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {filteredData.map((item, index) => (
        <Chip key={index} size="large" style={{ margin: 4 }}>
          {formatText(item.text)}
        </Chip>
      ))}
    </div>)
  }

  return (
    <ul>
      {filteredData.map((item, index) => (
        <li key={index}>{formatText(item.text)}</li>
      ))}
    </ul>
  );
}

function formatSeoMetaDescription(description, maxLength = 160) {
  description = description.trim();

  if (description?.length > maxLength) {
    description = description.substring(0, maxLength);
    const lastSpaceIndex = description.lastIndexOf(" ");

    if (lastSpaceIndex > 0) {
      description = description.substring(0, lastSpaceIndex);
    }

    description += "...";
  }

  return description;
}

function formatSeoTitle(job) {
  const { company, city, title } = job;

  if (!company) {
    return `Emploi ${title} | ${city}`;
  }

  return `Emploi ${title} | ${company} | ${city}`;
}


const getJobPostingSchema = (job) => {
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: job?.title,
    description: job?.description,
    identifier: {
      '@type': 'PropertyValue',
      name: job?.company
    },
    // employmentType: defaultContractsOptions.find((c) => c.value === offer?.fields?.contractType)?.googleJobsType(offer?.fields?.timeType),
    hiringOrganization: {
      '@type': 'Organization',
      name: job?.company,
      // logo: offer?.Company?.logo,
    },
    ...(job?.source_data?.dateActualisation || job?.source_data?.dateCreation || job?.publication_date) ? {
      datePosted: job?.source_data?.dateActualisation || job?.source_data?.dateCreation || job?.publication_date,
    } : {},
    ...job?.city ? {
      jobLocation: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: job?.city,
          // streetAddress: offer?.fields?.addr?.street,
          // postalCode: offer?.fields?.addr?.postalCode,
          addressCountry: 'FR',
        },
      },
    } : {},
  };

  window.prerenderReady = false;

  return JSON.stringify(schema);
};


export function JobPage(props) {
  const dispatch = useDispatch();
  const currentSearch = useSelector(state => {
    return state.global.currentSearch;
  });
  const currentSearchJobsPage = useSelector(state => props.match?.searchId ? state.jobs.groupIndexes.search[props.match?.searchId] : state.jobs.currentSearch);
  const currentSearchJobId = useSelector(state => props.match?.searchId ? state.jobs.search[props.match?.searchId].results[currentSearchJobsPage?.index]._id : null);

  const job = useSelector(state => state.jobs.data?.[currentSearchJobId || props.match.params.id]);
  const jobError = useSelector(state => state.jobs?.errors?.[currentSearchJobId || props.match.params.id]);
  const likeStatus = useSelector(state => state?.likes?.job?.[currentSearchJobId || props.match.params.id]);

  const gesture = useDrag((state) => {
    const [movementX, movementY] = state.movement;
    const threshold = 10;

    if (state.last && (currentSearch || props.match?.searchId)) {
      if (Math.abs(movementX) > threshold) {
        if (movementX > 0) {
          dispatch(getPreviousJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
        } else
          dispatch(getNextJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
      }
    }
  });

  // useEffect(() => { // should be move elsewhere
  //   console.log('refreshing job data');
  //   if (props.match?.searchId && job) {
  //     dispatch(getJob(job?._id));
  //   }
  // }, [job]);

  useEffect(() => { // for single job page
    if (!props.match?.searchId) {
      dispatch(getJob(props.match.params.id));
    }
  }, [job?.title]);

  if (jobError && typeof window !== 'undefined') {
    window.prerenderReady = false;
  }

  return (
    <div>
      {
        jobError && <Helmet>
          <meta name="prerender-status-code" content="404"></meta>
        </Helmet>
      }
      {job && <Helmet>
        <title>{formatSeoTitle(job)}</title>
        <meta name="description" content={formatSeoMetaDescription(job.description)} />
        <meta property="og:title" content={formatSeoTitle(job)} />
        <meta property="og:description" content={formatSeoMetaDescription(job.description)} />
        <script type="application/ld+json">
          {getJobPostingSchema(job)}
        </script>
      </Helmet>}
      {job &&
        <Like id={job._id} noDisplay />
      }
      {!props.match?.searchId && <ContainerTopCta>
        <Button
          onClick={() => {
            if (currentSearch) {
              dispatch(resetSearch());
            }

            dispatch(push('/'))
          }}
          active
          gutter="medium"
          variant="ghost"
          leftIcon="Sunlight">
          Trouver un job avec Ozly
        </Button>
      </ContainerTopCta>}
      <ContainerContent {...gesture()}>
        <Card style={{ maxWidth: 'none', overflow: 'hidden' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', padding: '32px 0px' }}>
            {!job?.companyLogo && <Icon name="Building" size="xlarge" gutter="medium" />}
            {job?.companyLogo && <Avatar size="xlarge" src={job?.companyLogo} gutter="medium" />}
            <Typo variant="heading.small" style={{ maxWidth: '70%', textAlign: 'center' }}>
              {job?.title}
            </Typo>

            <Typo variant="paragraph.medium.small">
              {job?.company}
            </Typo>
            {(currentSearch || props.match?.searchId) && <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onlyicon="ChevronLeft"
                size="small"
                gutter="medium"
                style={{
                  ...currentSearchJobsPage?.index === 0 && {
                    opacity: 0,
                    pointerEvents: 'none',
                  }
                }}
                disabled={currentSearchJobsPage?.index === 0}
                onClick={() => {
                  dispatch(getPreviousJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
                }}
              />
              {!currentSearchJobsPage?.isLastPage &&
                <Button
                  onlyicon="ChevronRight"
                  size="small"
                  gutter="medium"
                  // disabled={currentSearchJobsPage?.isLastPage}
                  onClick={() => {
                    dispatch(getNextJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
                  }}
                />}
            </div>}
          </div>
          {/* <div style={{ marginBottom: 16 }}> */}
          <JobBadges job={job} justifyContent="center" size="large" />
          {/* </div> */}
          {/* <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {job?.metadata_flags?.['has_CONTRACT_HOUR_PARTTIME'] &&
        <Chip size="large" leftIcon="Clock" gutter="small" mr="8">temps-partiel</Chip>
      }
      {job?.metadata_flags?.['has_CONTRACT_HOUR_FULLTIME'] &&
        <Chip size="large" leftIcon="Clock" gutter="small">temps-plein</Chip>
      }
      </div> */}
          {/* {job?.metadata_flags?.['has_CONTRACT_HOUR_DURATION'] && 
<React.Fragment>
<Divider text="hOUR" align="left" />
   {entitiesText({ labelType: 'CONTRACT_HOUR_DURATION', job, type: 'chip' })}
</React.Fragment>
} */}
          {job?.metadata_flags?.['has_JOB_TASKS'] &&
            <React.Fragment>
              <Divider text="Missions principales" align="left" bgColor="rgb(255, 247, 240)" />
              {entitiesText({ labelType: 'JOB_TASKS', job })}
            </React.Fragment>
          }
          <Divider text="Description" align="left" bgColor="rgb(255, 247, 240)" />
          <div dangerouslySetInnerHTML={{ __html: job?.description }} />

          {job?.metadata_flags?.['has_COMPANY_BENEFITS'] &&
            <React.Fragment>
              <Divider text="Bénéfices" align="left" bgColor="rgb(255, 247, 240)" />
              {entitiesText({ labelType: 'COMPANY_BENEFITS', job, type: 'chip' })}
            </React.Fragment>
          }
        </Card>
      </ContainerContent>
      {/* <Button style={{ margin: 'auto', display: 'flex', marginTop: 40, marginBottom: 40 }} leftIcon="Sunlight" gutter="medium" onClick={() => {
          dispatch(push(window.location.pathname + '/similar'));
      }}>
        Voir les offres similaires
      </Button> */}
      {/* {job && 
        <JobCarouselSimilar jobId={job?._id} />
      } */}
      <ContainerBottomRightActions>
        <FloatingButton
          leftIcon="Send"
          gutter="medium"
          onClick={() => {
            dispatch(sendEventAnalytics({
              event: 'click_apply_job',
              data: {
                origin: 'job_page',
              }
            }));
            window.open(job?.url, '_blank');
          }}
        >
          Postuler
        </FloatingButton>
        <Tooltip text="Enregistrer l’offre">
          <FloatingButton
            onlyicon={likeStatus?.isLiked ? 'HeartFull' : 'Heart'}
            gutter="medium"
            variant="secondary"
            // iconProps={{
            //   color: 'brown.400'

            // }}
            onClick={() => {
              dispatch(likeElement(job?._id, 'job', false, likeStatus?.isLiked));
            }}
          />
        </Tooltip>
        <Tooltip text="Partager l’offre">
          <FloatingButton
            onlyicon="Share"
            variant="secondary"
            onClick={() => {
              dispatch(sendEventAnalytics({
                event: 'click_share_job',
                data: {
                  origin: 'home',
                }
              }));
              dispatch(openDialog('jobShare', { title: job?.title, subtitle: job?.company + ' - ' + job?.city, job }));
            }}
          />
        </Tooltip>
      </ContainerBottomRightActions>
      <ContainerBottomActionsMobile>
        <ActionsBar>
          <Button
            size="medium"
            gutter="none"
            variant="ghost"
            onlyicon="Back"
            onClick={() => {
              if (window.location.pathname === '/') {
                dispatch(closeSidebar('main'))
              } else {
                  dispatch(push('/'))
              }
            }}
          />
          <Button
            size="medium"
            gutter="none"
            variant="ghost"
            onClick={() => {
              dispatch(likeElement(job?._id, 'job', false, likeStatus?.isLiked));
            }}
            onlyicon={likeStatus?.isLiked ? 'HeartFull' : 'Heart'} />
          <Button
            size="medium"
            gutter="none"
            variant="ghost"
            onlyicon="Share"
            onClick={() => {
              dispatch(sendEventAnalytics({
                event: 'click_share_job',
                data: {
                  origin: 'home',
                }
              }));
              dispatch(openDialog('jobShare', { title: job?.title, subtitle: job?.company + ' - ' + job?.city, job }));
            }} />
          {/* <Button
            size="medium"
            gutter="none"
            variant="conversion"
            onlyicon="Briefcase"
            onClick={() => {
              dispatch(sendEventAnalytics({
                event: 'click_apply_job',
                data: {
                  origin: 'job_page',
                }
              }));
              window.open(job?.url, '_blank');
            }}
          /> */}
            <Button
            size="medium"
            gutter="none"
            variant="conversion"
            onClick={() => {
              dispatch(sendEventAnalytics({
                event: 'click_apply_job',
                data: {
                  origin: 'job_page',
                }
              }));
              window.open(job?.url, '_blank');
            }}
          >Postuler</Button>
        </ActionsBar>
      </ContainerBottomActionsMobile>
    </div>
  );
}

JobPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  jobPage: makeSelectJobPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(JobPage);


