import * as types from './types';

export function getAlerts(options) {
    return {
        type: [types.GET_USER_ALERTS, types.GET_USER_ALERTS_SUCCESS, types.GET_USER_ALERTS_FAIL],
        baseUrl: '/api/alerts',
        requestType: 'get',
        body: options,
        loader: 'getAlerts',
        toasts: {
            fail: {
                message: 'Vos alertes n’ont pas pu être récupérées :(',
            },
        }
    };
}

export function addAlert(body, options) {
    return {
        type: [types.ADD_USER_ALERT, types.ADD_USER_ALERT_SUCCESS, types.ADD_USER_ALERT_FAIL],
        baseUrl: '/api/alerts',
        requestType: 'post',
        body,
        ...options,
        loader: 'addAlert',
        dialogs: {
            close: 'userAlertsCreate',
        },
        toasts: {
            success: {
                message: 'Votre alerte a été enregistrée :)',
                params: {
                    actionLabel: 'Gérer les alertes',
                    actionRedirect: '/alerts',
                },
            },
            fail: {
                message: 'Votre alerte n’a pas pu être enregistrée :(',
            }
        }
    };
}

export function deleteAlert(id) {
    return {
        type: [types.DELETE_USER_ALERT, types.DELETE_USER_ALERT_SUCCESS, types.DELETE_USER_ALERT_FAIL],
        baseUrl: `/api/alerts/${id}`,
        requestType: 'delete',
        analytics: {
            on: 'success',
            amplitude: {
                event: 'remove_alert',
                data: {
                    alert_id: id
                },
            }
        },
        toasts: {
            success: {
                message: 'Votre alerte a été supprimée :)',
            },
            fail: {
                message: 'Votre alerte n’a pas pu être supprimée :(',
            },
        }
    };
}


export function addKeepInTouch(body) {
    return {
        type: [types.ADD_KEEP_IN_TOUCH, types.ADD_KEEP_IN_TOUCH_SUCCESS, types.ADD_KEEP_IN_TOUCH_FAIL],
        baseUrl: '/api/keep-in-touch',
        requestType: 'post',
        body,
        loader: 'addKeepInTouch',
        dialogs: {
            close: 'userAlertsKeepInTouch',
        },
        toasts: {
            success: {
                message: 'On se retrouve bientôt :)',
            },
        }
    };
}