
export const GET_USER_LIKES = 'GET_USER_LIKES';
export const GET_USER_LIKES_SUCCESS = 'GET_USER_LIKES_SUCCESS';
export const GET_USER_LIKES_FAIL = 'GET_USER_LIKES_FAIL';

export const LIKE_ELEMENT = 'LIKE_ELEMENT';
export const LIKE_ELEMENT_SUCCESS = 'LIKE_ELEMENT_SUCCESS';
export const LIKE_ELEMENT_FAIL = 'LIKE_ELEMENT_FAIL';

export const GET_LIKE_STATUS = 'GET_LIKE_STATUS';
export const GET_LIKE_STATUS_SUCCESS = 'GET_LIKE_STATUS_SUCCESS';
export const GET_LIKE_STATUS_FAIL = 'GET_LIKE_STATUS_FAIL';