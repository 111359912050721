import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector(state => state.global.user);
    const initializing = useSelector(state => state.global.initializing);

    if (initializing) {
        return (<div>loading</div>);
    }

    return (
        <Route
            {...rest}
            render={props =>
                (user && user._id.search('temp') === -1) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default PrivateRoute;
