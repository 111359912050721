import React, { useState } from 'react';

function Slide({
    children,
    onClick
  }) {
    const [downPos, setDownPos] = useState(null);
  
    const handleMouseDown = (e) => {
      setDownPos({ x: e.clientX, y: e.clientY });
    };
  
    const handleMouseUp = (e) => {
      if (!downPos) return e.stopPropagation();

      const upPos = { x: e.clientX, y: e.clientY };
      const posDiff = Math.sqrt(Math.pow(upPos?.x - downPos.x, 2) + Math.pow(upPos?.y - downPos.y, 2));
  
      if (posDiff > 10) {
        e.stopPropagation();
      } else {
        onClick(e);
      }
    };
  
    return (
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {children}
      </div>
    );
  }

  export default Slide;