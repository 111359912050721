import { SEARCH_JOBS_SUCCESS } from '../../containers/App/constants';
import { push } from 'connected-react-router';
import { openDialog } from '../dialogs/actions';
import { formatRagElementURL } from 'utils/urlSeo';
import * as types from '../jobs/types';
import { invokeChain } from '../chat/actions';

const knownCrawlers = [
  // Google
  'googlebot', 
  'adsbot-google',
  'mediapartners-google',

  // Microsoft Bing
  'bingbot', 
  'msnbot',
  'bingpreview',

  // Yahoo
  'slurp',

  // Baidu
  'baiduspider',

  // Yandex
  'yandex',

  // DuckDuckGo
  'duckduckbot',

  // Apple
  'applebot',

  // Other notable crawlers & bots
  'facebookexternalhit',
  'twitterbot',
  'linkedinbot',
  'pinterest',
  'alexa',
  'archive.org_bot',
  'seznambot',
  'Prerender'
];

const userAgent = navigator.userAgent.toLowerCase();
export const isCrawler = knownCrawlers.some(crawler => userAgent.includes(crawler));

const ragelementsMiddleware = (store) => (next) => (action) => {
  const { type } = action;

  if (type === SEARCH_JOBS_SUCCESS) {
    const transcript = action.payload.transcript;

    if (!isCrawler) {
      if (typeof transcript !== 'undefined' && transcript !== '') {
        store.dispatch(push(`/search?query=${transcript}`));
      }
    }

      // setTimeout(() => {
      //   const currentSearch = store.getState().global.currentSearch;

      //   if (currentSearch) {
      //     store.dispatch(openDialog('userAlertsCreate', { query: currentSearch?.transcript, origin: 'active_search_2mins' }));
      //   }
      // }, 0.1 * 60 * 1000);
  }

    switch (type) {
        case types.GET_NEXT_JOB: {
            const state = store.getState();
            const currentResults = action.payload?.groupIndexes ? state.ragelements?.[action.payload.groupType][action.payload.groupValue]?.results : state.global.currentSearch.results;
            const currentIndex = currentResults.findIndex(ragelement => ragelement._id === action.payload.ragelementId);

            if (currentIndex < currentResults.length - 1) {
                  store.dispatch({
                    type: types.GET_NEXT_JOB_DONE,
                    payload: {
                        index: currentIndex + 1,
                        isLastPage: currentIndex + 1 >= currentResults.length - 1,
                        groupIndexes: action.payload?.groupIndexes,
                        groupType: action.payload?.groupType,
                        groupValue: action.payload?.groupValue,
                    },
                });

                if (!action.payload?.groupIndexes) {
                  store.dispatch(push(formatRagElementURL(currentResults[currentIndex + 1])));
                }
            }

            break;
        }

        case types.GET_PREVIOUS_JOB: {
            const state = store.getState();
            const currentResults = action.payload?.groupIndexes ? state.ragelements?.[action.payload.groupType][action.payload.groupValue]?.results : state.global.currentSearch.results;
            const currentIndex = currentResults.findIndex(ragelement => ragelement._id === action.payload.ragelementId);

            if (currentIndex > 0) {
                store.dispatch({
                    type: types.GET_PREVIOUS_JOB_DONE,
                    payload: {
                        index: currentIndex - 1,
                        isLastPage: false,
                        groupIndexes: action.payload?.groupIndexes,
                        groupType: action.payload?.groupType,
                        groupValue: action.payload?.groupValue,
                    },
                });

                if (!action.payload?.groupIndexes) {
                  store.dispatch(push(formatRagElementURL(currentResults[currentIndex - 1])));
                }
            }

            break;
          }

        case types.GET_JOBS_BY_SEARCH_ID_SUCCESS: {
            console.log('GET_JOBS_BY_SEARCH_ID_SUCCESS', action);
            if (action.payload.results.length === 0) {
              store.dispatch(invokeChain('chat/ragelement/search/noresult', { input: {}}, true));
            }
            break;
        }
        
        default:
            return next(action);
    }


  return next(action);
};

export default ragelementsMiddleware;
