import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the ragElementPage state domain
 */

const selectRagElementPageDomain = state => state.ragElementPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by RagElementPage
 */

const makeSelectRagElementPage = () =>
  createSelector(
    selectRagElementPageDomain,
    substate => substate,
  );

export default makeSelectRagElementPage;
export { selectRagElementPageDomain };
