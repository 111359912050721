import * as types from './types';

export function getLikes(options) {
    return {
        type: [types.GET_USER_LIKES, types.GET_USER_LIKES_SUCCESS, types.GET_USER_LIKES_FAIL],
        baseUrl: '/api/likes/',
        requestType: 'get',
        body: options,
        loader: 'getLikes',
        toasts: {
            fail: {
                message: 'Vos offres enregistrées n’ont pas pu être récupérées :(',
            },
        },
    };
}

export function likeElement(id, type = 'job', refreshLikes = false, currentState) {
    return {
        type: [types.LIKE_ELEMENT, types.LIKE_ELEMENT_SUCCESS, types.LIKE_ELEMENT_FAIL],
        baseUrl: `/api/likes/${type}`,
        requestType: 'post',
        body: {
            id
        },
        like: {
            id,
            type,
            refreshLikes,
        },
        ...currentState ? {
            toasts: {
                success: {
                  message: 'Favori retiré :)',
                },
                fail: {
                  message: "Ce favori n'a pas pu être retiré :(",
                },
              },
            analytics: {
                amplitude: {
                    event: 'unlike',
                    data: {
                        id,
                        like_type: type,
                    },
                }
            }
        } : {
            toasts: {
                success: {
                  message: 'Favori ajouté :)',
                  params: {
                    actionLabel: 'Voir les favoris',
                    actionRedirect: '/likes',
                  },
                },
                fail: {
                  message: "Ce favori n'a pas pu être enregistré :(",
                },
            },
            analytics: {
                amplitude: {
                    event: 'like',
                    data: {
                        id,
                        like_type: type,
                    },
                }
            }
        }
    };
}

export function getLikeStatus(id, type = 'job') {
    return {
        type: [types.GET_LIKE_STATUS, types.GET_LIKE_STATUS_SUCCESS, types.GET_LIKE_STATUS_FAIL],
        baseUrl: `/api/likes/${type}/${id}`,
        requestType: 'get',
        like: {
            id,
            type,
        },
        takeEvery: true,
    };
}

