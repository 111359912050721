/**
 *
 * RagElementPage
 *
 */

import React, { memo,  useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useDrag } from '@use-gesture/react';
import { getRagElement } from '../../redux/ragElements/actions';
import makeSelectRagElementPage from './selectors';
import { Button } from '@ozlydev/ozly-sand-ui';
import * as formatters from './formatters';


const ContainerBottomRightActions = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 64px;
  bottom: 64px;
  align-items: flex-end;
  z-index: 2;

  @media (max-width: 768px) {
    right: 32px;
    bottom: 32px;
  }

  @media (max-width: 480px) {
    right: 16px;
    bottom: 16px;
  }
`;


const ContainerTopCta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 152px;

  @media (max-width: 768px) {
    margin-top: 112px;
  }
`;

function formatText(text) {
  const strippedText = text.replace(/<[^>]*>?/gm, '');
  const cleanedText = strippedText.replace(/\n/g, ' ').trim();
  const formattedText = cleanedText.charAt(0).toUpperCase() + cleanedText.slice(1).toLowerCase();

  return formattedText;
}



function formatSeoMetaDescription(description, maxLength = 160) {
  description = description?.trim() || "";

  if (description?.length > maxLength) {
      description = description.substring(0, maxLength);
      const lastSpaceIndex = description.lastIndexOf(" ");

      if (lastSpaceIndex > 0) {
          description = description.substring(0, lastSpaceIndex);
      }

      description += "...";
  }

  return description;
}

export function RagElementPage(props) {
  const dispatch = useDispatch();
  const currentSearch = useSelector(state => {
    return state.global.currentSearch;
  });

  const currentId = props.match.id;
  const ragType = props.match.type;
  const coordinates = props.match.coordinates;
  const distance = props.match.distance;

  const ragElement = useSelector(state => state.ragElements.data?.[currentId]);;
  const ragElementError = useSelector(state => state.ragElements?.errors?.[currentId]);
  
  const gesture = useDrag((state) => {
    const [movementX, movementY] = state.movement;
    const threshold = 10;

    // if (state.last && (currentSearch || props.match?.id)) {
    //   if (Math.abs(movementX) > threshold) {
    //     if (movementX > 0) {
    //       dispatch(getPreviousRagElement(ragElement?._id, props.match?.id ? true : false, 'search', props.match?.id));
    //     } else 
    //       dispatch(getNextRagElement(ragElement?._id, props.match?.id ? true : false, 'search', props.match?.id));
    //     }
    //   }
  });

  useEffect(() => { // for single ragElement page
    dispatch(getRagElement(currentId, ragType, {
      coordinates,
      distance
    }));
  }, [props.match.id]);

  if (ragElementError && typeof window !== 'undefined') {
    window.prerenderReady = true;
  }  

  return (
    <div>
      {
        ragElementError && <Helmet>
            <meta name="prerender-status-code" content="404"></meta>
        </Helmet>
      }
      {ragElement && <Helmet>
        <title>{ragElement.title}</title>
        <meta name="description" content={formatSeoMetaDescription(ragElement.nature_travail)} />
        <meta property="og:title" content={ragElement.title} />
        {/* <meta property="og:description" content={formatSeoMetaDescription(ragElement.nature_travail)} />
        <script type="application/ld+json">
            {getRagElementPostingSchema(ragElement)}
          </script> */}
      </Helmet>}

      <div {...gesture()} >
          { formatters[ragType](ragElement) }
      </div>

      {(currentSearch || props.match?.searchId) && (
          <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', justifyContent: 'space-between'}}>
              <Button
                  onlyicon="ChevronLeft"
                  size="small"
                  gutter="medium"
                  style={{
                    ...currentSearchJobsPage?.index === 0 && {
                      opacity: 0,
                      pointerEvents: 'none',
                    }
                  }}
                  disabled={currentSearchJobsPage?.index === 0}
                  onClick={() => {
                    dispatch(getPreviousJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
                  }}
              />
              { !currentSearchJobsPage?.isLastPage &&
                  <Button
                      onlyicon="ChevronRight"
                      size="small"
                      gutter="medium"
                      // disabled={currentSearchJobsPage?.isLastPage}
                      onClick={() => {
                          dispatch(getNextJob(job?._id, props.match?.searchId ? true : false, 'search', props.match?.searchId));
                      }}
                  />
              }
          </div>
      )}
    </div>
  );
}

RagElementPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  ragElementPage: makeSelectRagElementPage(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withConnect,
  memo,
)(RagElementPage);


