import { showAndRemove } from './actions';

const toastMiddleware = (store) => (next) => (action) => {
  const { toasts } = action;
  const { type, error } = action;

  const errorApi = error?.statusText || false;

  if ((!toasts || Array.isArray(type)) && !errorApi) {
    return next(action);
  }

  if (toasts?.success && type?.endsWith('_SUCCESS')) {
      store.dispatch(showAndRemove(toasts.success.message, toasts?.success?.params));
  }

  if ((toasts?.fail || errorApi) && type?.endsWith('_FAIL')) {
      const doNotDisplay = ['Unauthorized'];

      if (!doNotDisplay.includes(errorApi)) {
        store.dispatch(showAndRemove(errorApi ? (
          error?.message || (toasts?.fail?.message || errorApi)
        ) : toasts.fail.message), toasts?.fail?.params);
      }
  }

  return next(action);
};

export default toastMiddleware;
