import * as types from './types';

const initialState = {
    characters: [],
    agentsByKeys: {},
};

const agentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_AGENTS_CHARACTERS_SUCCESS: {
            return {
                ...state,
                characters: action.payload.characters,
            };
        }

        default:
            return state;
    }
};

export default agentsReducer;
