function truncateString(str, maxLength) {
    if (!str || str.length <= maxLength) {
        return str;
    } else {
        const lastSpaceIndex = str.lastIndexOf(' ', maxLength);

        if (lastSpaceIndex === -1) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str.substring(0, lastSpaceIndex) + '...';
        }
    }
}

export {
    truncateString
}
