import * as types from './types';
import { getChatHistory } from "../chat/actions";

const agentMiddleware = (store) => (next) => (action) => {

  const { type } = action;

  if (type === types.GET_AGENTS_CHARACTERS_SUCCESS) {
    store.dispatch(getChatHistory());
  }

  return next(action);
};

export default agentMiddleware;


