import * as types from './types';

export function getRagElement(id, type, query) {
  return {
    type: [types.GET_RAGELEMENT, types.GET_RAGELEMENT_SUCCESS, types.GET_RAGELEMENT_FAIL],
    baseUrl: `/api/rag/${type}/${id}`,
    requestType: 'get',
    query,
    loader: 'getRagElement',
    toasts: {
      fail: {
        message: 'Le ragelement n’a pas pu être récupérée :(.',
      },
    },
    ragelements: {
      ragelementId: id,
    },
  };
}

export function getRagElememtsByIds(ids, type = 'metier', query) {
  return {
    type: [types.GET_RAGELEMENT_BY_IDS, types.GET_RAGELEMENT_BY_IDS_SUCCESS, types.GET_RAGELEMENT_BY_IDS_FAIL],
    baseUrl: `/api/rag/${type}/ids/${ids.join(',')}`,
    requestType: 'get',
    query,
    loader: 'getRagElement',
    toasts: {
      fail: {
        message: 'Le rag_element n’a pas pu être récupérée :(.',
      },
    },
    ragelements: {
      ragelementIds: ids,
      similarType: type,
    },
    takeEvery: true,
  };
}

