import * as types from './types';

export function getJob(id) {
  return {
    type: [types.GET_JOB, types.GET_JOB_SUCCESS, types.GET_JOB_FAIL],
    baseUrl: `/api/jobs/${id}`,
    requestType: 'get',
    loader: 'getJob',
    toasts: {
      fail: {
        message: 'L’offre n’a pas pu être récupérée :(.',
      },
    },
    jobs: {
      jobId: id,
    },
  };
}

export function getJobSimilar(id, type = 'default', showViewed = true) {
  return {
    type: [types.GET_JOB_SIMILAR, types.GET_JOB_SIMILAR_SUCCESS, types.GET_JOB_SIMILAR_FAIL],
    baseUrl: `/api/jobs/${id}/similar/${type}?showViewed=${showViewed}`,
    requestType: 'get',
    loader: 'getJobSimilar',
    jobs: {
      jobId: id,
      similarType: type,
    },
    takeEvery: true,
  };
}

export function setJobData(data) {
  return {
    type: types.SET_JOB_DATA,
    data,
  };
}

export function getNextJob(id, groupIndexes, groupType, groupValue) {
  return {
    type: types.GET_NEXT_JOB,
    payload: {
      jobId: id,
      groupIndexes,
      groupType,
      groupValue,
    }
  };
}

export function getPreviousJob(id, groupIndexes, groupType, groupValue) {
  return {
    type: types.GET_PREVIOUS_JOB,
    payload: {
      jobId: id,
      groupIndexes,
      groupType,
      groupValue,
    }
  };
}


export function getJobsBySearchId(id) {
  return {
    type: [types.GET_JOBS_BY_SEARCH_ID, types.GET_JOBS_BY_SEARCH_ID_SUCCESS, types.GET_JOBS_BY_SEARCH_ID_FAIL],
    baseUrl:`/api/jobs/search/${id}`,
    requestType: 'get',
    loader: 'searchJobsBySearchId',
    search: {
      id,
    },
    takeEvery: true,
  };
}
