import React, { useEffect } from 'react';
// import ReactPixel from 'react-facebook-pixel';
import * as amplitude from '@amplitude/analytics-browser';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const isProd = process.env.NODE_ENV === 'production';

const tagManagerArgs = {
  gtmId: 'GTM-NKQQVHC',
};

const init = () => {
  if (isProd) {
   amplitude.init(process.env.AMPLITUDE_API_KEY, false,   
    {
      defaultTracking: {
        pageViews: true,
        sessions: true,
        fileDownloads: true,
        // formInteractions: true,
  }});
    // TagManager.initialize(tagManagerArgs);
    // ReactPixel.init('1559625054847232');
  }
}




export const initUserAnalytics = (user, event, origin) => {
  if (isProd) {
    if (user?.allowDataCollection === false) {
      disableUserAnalytics();
      return;
    }

    const userIsGuest = user._id.search('temp') !== -1;
    TagManager.initialize({
      ...tagManagerArgs,
      dataLayer: {
        user_id: user._id,
        user_is_guest: userIsGuest,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        user_id: user._id,
        event,
        origin,
      }});

    amplitude.setUserId(user._id);

    const id = new amplitude.Identify();
    id.set('user_is_guest', userIsGuest);

    amplitude.identify(id);
    amplitude.logEvent(event, {
      origin
    });
  }
};

export const disableUserAnalytics = () => {
  if (isProd) {
    amplitude.setOptOut(true);
  }
};

export const enableUserAnalytics = () => {
  if (isProd) {
    amplitude.setOptOut(false);
  }
};

export function usePageViews() {
  let location = useLocation();

  if (isProd) {
      // ReactPixel.pageView();
  }
};

init();


