/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';

import PrivateRoute from '../../components/Routing/PrivateRoute';

import toastSaga from '../../redux/toasts/saga';
import dialogSaga from '../../redux/dialogs/saga';

import HomePage from 'containers/HomePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import RegisterPage from 'containers/RegisterPage/Loadable';
import LoginPage from 'containers/LoginPage/Loadable';
import ChatPage from 'containers/ChatPage/Loadable';
import AgentsPage from 'containers/AgentsPage/Loadable';
import LogoutPage from 'containers/LogoutPage/Loadable';
import LikesPage from 'containers/LikesPage/Loadable';
import AlertsPage from 'containers/AlertsPage/Loadable';
import HistoryPage from 'containers/HistoryPage/Loadable';
import JobPage from 'containers/JobPage/Loadable';
import JobSimilarPage from 'containers/JobSimilarPage/Loadable';
import SettingsPage from 'containers/SettingsPage/Loadable';
import MagicLinkValidationPage from 'containers/MagicLinkValidationPage/Loadable';
import EmailValidationPage from 'containers/EmailValidationPage/Loadable';

import Header from 'components/Header';
import Footer from '../../components/Footer';
import ChatMenu from '../../components/Chat/ChatMenu';
import ChatSidebar from '../../components/Chat/ChatSidebar';

import GlobalStyle from '../../global-styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ThemeProvider, darkTheme, SinoLines } from '@ozlydev/ozly-sand-ui';
import Dialogs from '../../components/Dialogs/Loadable';
import Toasts from '../../components/Toasts/Loadable';
import { usePageViews } from '../../modules/analytics';
import '@ozlydev/ozly-sand-ui/styles.css';

const AppWrapper = styled.div`
  max-width: calc(768px + 16px * 2);
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;

  ${(props) => props.$fullWidth
    && css`
      max-width: 100%;
      padding: 0 16px;
  `}
`;

export default function App() {
  useInjectSaga({ key: 'toasts', saga: toastSaga });
  useInjectSaga({ key: 'dialogs', saga: dialogSaga });
  const agents = useSelector((state) => state.agents.characters);
  const agent = agents.find((a) => a.pathNames?.includes(window.location.pathname));
  const chatIsResponding = useSelector((state) => state.chat.chatIsResponding);

  usePageViews();

  return (
    <ThemeProvider theme={darkTheme}>
      <AppWrapper $fullWidth={agent != null}>
        <div style={{ 
          position: 'fixed',
          pointerEvents: 'none',
          left: 0,
          right: 0,
          top: 250,
          }}
        >
{/*          <SinoLines
            height={600}
            style={{
              position: 'fixed',
              bottom: 0,
              pointerEvents: 'none',
              margin: 0,
            }}
            // generalSpeed={50}
            generalSpeed={chatIsResponding ? 13 : 0.2}
            lines={[
              {
                speed: 1.3,
                numPoints: 10,
                spikiness: 1,
            },
            {
                speed: 1.2,
                numPoints: 14,
                spikiness: 0
            },
            {
                speed: 1,
                numPoints: 8
            },
            {
                speed: 1.4,
                numPoints: 12
            }
            ]}
          /> */}
        </div>
        <Helmet titleTemplate="%s - Ozly" defaultTitle="Ozly" />
        <Header />
        {agent == null && (
          <ChatMenu />
        )}
        <Switch>
          {/* <Route exact path={["/", "/search"]} component={HomePage} /> */}

          <Route exact path={["/"]} component={AgentsPage} />
          <Route path={["/agents"]} component={AgentsPage} />

          <Route path="/job/:slug/:id/similar" component={JobSimilarPage} />
          <Route path="/job/:slug/:id" component={JobPage} />
          <Route exact path="/jobs/region/:region" component={HomePage} />
          <Route exact path="/jobs/departement/:dep" component={HomePage} />
          <Route exact path="/jobs/ville/:city" component={HomePage} />

          <Route path="/likes" component={LikesPage} />
          <Route path="/history" component={HistoryPage} />
          <Route path="/alerts" component={AlertsPage} />

          <Route path="/register" component={RegisterPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/logout" component={LogoutPage} />
    
          <Route path="/magiclink/validate/:token" component={MagicLinkValidationPage} />
          <Route path="/email/validate/:token" component={EmailValidationPage} />

          <PrivateRoute path="/settings" component={SettingsPage} />

          <Route path="/email/validate/:token" component={EmailValidationPage} />

          <Route path="/:agent" component={ChatPage} />
        </Switch>
        {/* <Footer /> */}
        <GlobalStyle />
        <Toasts />
        <Dialogs />
        <Footer />
     
      </AppWrapper>
    </ThemeProvider>
  );
}
