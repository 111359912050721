import React from 'react';
import styled from 'styled-components';
import { FloatingButton, Card, Typo, Button, Divider, Chip, Icon, Avatar, Tooltip, ActionsBar } from '@ozlydev/ozly-sand-ui';
import { useDispatch } from 'react-redux';
import { closeSidebar } from '../../../redux/sidebar/actions';

const ContainerBottomActionsMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    position: fixed;
    flex-direction: column;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 32px;

    > div {
      background-color: #FFF7F0;
      display: flex;
      align-items: center;
    }
  }
`;


const Template = (data) => {
    const dispatch = useDispatch();

    const bgColor = 'rgb(255, 247, 240)';

    const cond = (label, content = "", html = true) => {
        if (content) {
            return (
                <React.Fragment>
                    <Divider text={label} align="left" bgColor={bgColor} />

                    {html ? (
                        <div dangerouslySetInnerHTML={{
                            __html: content
                        }} />
                    ) : (
                        <div>
                            {content}
                        </div>
                    )}
                </React.Fragment>
            )
        }
    }

    return (
        <Card style={{ maxWidth: 'none', overflow: 'hidden', padding: 0, paddingBottom: 100 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', padding: '32px 0px' }}>
            {!data?.companyLogo && <Icon name="Building" size="xlarge"  gutter="medium" />}
            {data?.companyLogo && <Avatar size="xlarge" src={data?.companyLogo} gutter="medium" />}
            <Typo variant="heading.small" style={{ maxWidth: '70%', textAlign: 'center' }}>
                {data?.title}
            </Typo>
            <div>
                <Chip size="large" style={{ margin: 4 }}>
                    {data?.fields?.contractType}
                </Chip>
                {/* <Chip size="large" style={{ margin: 4 }}>
                    {data?.statut}
                </Chip> */}
            </div>
            {/* <Typo variant="paragraph.medium.small">
                {data?.company}
            </Typo> */}
            {/* {(currentSearch || props.match?.id) && (
                <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    onlyicon="ChevronLeft"
                    size="small"
                    gutter="medium"
                    style={{
                    ...currentSearchRagElementsPage?.index === 0 && {
                        opacity: 0,
                        pointerEvents: 'none',
                    }
                    }}
                    disabled={currentSearchRagElementsPage?.index === 0}
                    onClick={() => {
                    dispatch(getPreviousRagElement(data?._id, props.match?.id ? true : false, 'search', props.match?.id));
                    }}
                    />
                    { !currentSearchRagElementsPage?.isLastPage &&
                    <Button
                    onlyicon="ChevronRight"
                    size="small"
                    gutter="medium"
                    // disabled={currentSearchRagElementsPage?.isLastPage}
                    onClick={() => {
                    dispatch(getNextRagElement(data?._id, props.match?.id ? true : false, 'search', props.match?.id));
                    }}
                />}
                </div>
            )} */}
            </div>

            {cond('Description', data?.description)}
            {cond('Profil recherché', data?.fields?.descriptionProfil)}
            {/* {cond('Téléphone', data?.telephone)}
            {cond('Langues étrangères enseignées', data?.langues_enseignees)}
            {cond('Journées portes ouvertes', data?.journees_portes_ouvertes)}
            {cond('Fiche Onisep', <a href={data?.url_et_id_onisep} target="_blank">{data?.title}</a>, false)} */}

            <ContainerBottomActionsMobile>
                <ActionsBar>
                    <Button
                        size="medium"
                        gutter="none"
                        variant="ghost"
                        onlyicon="Back"
                        onClick={() => {
                            dispatch(closeSidebar('main'));
                        }}
                    />
                </ActionsBar>
            </ContainerBottomActionsMobile>
        </Card>
    )
}

export default Template