import * as types from './types';

const initialState = {
    system_prompt: `Tu es Ozly, un conseillère d’orientation amical, experte du marché du travail. Tu ne réponds qu’à des questions sur l’emploi.
    Pour lancer une recherche, il te faut à minima le poste que l’utilisateur recherche et le lieu du poste.
    Tu dois utiliser ces critères pour réaliser une recherche efficace et proposer les meilleures offres d’emploi. Si, tu n’as pas trouvé d’offres d’emploi qui correspondent aux critères recherchés, informe l’utilisateur propose lui d’ajouter cette recherche dans ses alertes ou de modifier ses critères
    Tu es toujours encourageante et enthousiaste. Tu écris d’un ton doux. Tu aimes bien commenter chaque réponse de manière bienveillante avant de poser des questions. Tu dois poser une seule question par message.`,
    messages: [],
    tools_status: {
        search: [],
    },
    chatIsResponding: false
};

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_CHAT_HISTORY_DONE: {
            return {
                ...state,
                messages: [],
            };
        }

        case types.UPDATE_SYSTEM_PROMPT: {
            return {
                ...state,
                system_prompt: action.payload,
            };
        }

        case types.UPDATE_STATUS_CHAT_TOOL: {
            return {
                ...state,
                tools_status: {
                    ...state.tools_status,
                    [action.payload.message.type]: [
                        ...state.tools_status[action.payload.message.type],
                        action.payload.message.content,
                    ],
                }
            };
        }

        case types.ADD_MESSAGE: {
            const messages = [
                ...state.messages
            ]

            const idMessage = action?.payload?.additional_kwargs?.idMessage || action?.payload?.idMessage || null;

            if (action?.payload?.author === 'mine') {
                messages.push(
                    {
                        text: action.payload.text,
                        date: new Date().toISOString(),
                        author: action?.payload?.author,
                        additional_kwargs: action?.payload?.additional_kwargs || {}
                    }
                )
            } else {
                if (messages.length > 0 && idMessage === messages?.[messages.length - 1]?.idMessage) {
                    messages[messages.length - 1] = {
                        ...messages[messages.length - 1],
                        text: action.payload.text,
                        date: new Date().toISOString(),
                        author: action?.payload?.author || "other",
                        idMessage,
                        additional_kwargs: action?.payload?.additional_kwargs || {}
                    };
                }
                else {
                    messages.push({
                        text: action.payload.text,
                        date: new Date().toISOString(),
                        author: action?.payload?.author || "other",
                        idMessage,
                        additional_kwargs: action?.payload?.additional_kwargs || {}
                    });
                }
            }

            return {
                ...state,
                messages,
                chatIsResponding: false
            };
        }

        case types.INVOKE_CHAIN: {
            return {
                ...state,
                chatIsResponding: true
            }
        }

        case types.INVOKE_CHAIN_SUCCESS: {
            return {
                ...state,
                chatIsResponding: false
            }
        }

        case types.STREAM_MESSAGE: {
            let messages = state.messages;
            const idMessage = action.payload.message.idMessage;            

            if (action.payload.message.action == null) {
                if (messages[messages.length - 1].idMessage === idMessage) {
                    messages[messages.length - 1] = {
                        ...messages[messages.length - 1],
                        text: action.payload.message.text,
                        last: action.payload.message.last,
                        additional_kwargs: action?.payload?.message.additional_kwargs || {}
                    };
                }
                else {
                    messages = [
                        ...messages,
                        {
                            text: action.payload.message.text,
                            last: action.payload.message.last,
                            date: new Date().toISOString(),
                            author: "other",
                            isStreamed: true,
                            idMessage,
                            additional_kwargs: action?.payload?.message.additional_kwargs || {}
                        }
                    ];
                }
            }

            if (action.payload.message.action != null) {
                messages = [
                    ...messages,
                    {
                        text: '',
                        action: action.payload.message.action,
                        date: new Date().toISOString(),
                        author: "other",
                        isStreamed: true,
                        idMessage,
                        last: action.payload.message.last,
                        additional_kwargs: {
                            action: action.payload.message.action,
                            data: action.payload.message.data,
                            coordinates: action.payload.message.additional_kwargs?.coordinates,
                            distance: action.payload.message.additional_kwargs?.distance,
                            ...action?.payload?.message.additional_kwargs || {}
                        }
                    }
                ];
            }
            

            return {
                ...state,
                messages: [
                    ...messages
                ],
                chatIsResponding: true
            };
        }

        case types.GET_CHAT_HISTORY_SUCCESS: {
            const messages = action.payload.messages.map((message) => {
                return {
                    action: message.additional_kwargs?.action,
                    text: message.content,
                    date: new Date(message.date),
                    author: message.role === 'assistant' ? 'other' : 'mine',
                    additional_kwargs: message.additional_kwargs,
                };
            });

            return {
                ...state,
                messages
            };
        }


        default:
            return state;
    }
};

export default chatReducer;
