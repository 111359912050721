import React from 'react';
import styled from 'styled-components';
import { Typo, Chip } from '@ozlydev/ozly-sand-ui';

const ThemeCardContainer = styled.div`
    padding: 16px;
    border: 1px solid #F0D4C2;
    border-radius: 16px;
    margin-bottom: 24px;
    text-align: center;
`;

const ThemeCardImg = styled.div`
    width: 100%;
    background-color: #F0D4C2;
    border-radius: 4px;
    margin-bottom: 16px;
    height: 94px;
    background-image: ${(props) => `url(${props.src})`};
    background-size: contain;
    background-position: center;
`;

const ThemeCard = React.memo(({
    title,
    subtitle,
    img,
}) => {
    return (
        <ThemeCardContainer>
            <ThemeCardImg src={img} />
            <Typo variant="paragraph.bold.medium">{title}</Typo>
            <Typo variant="paragraph.regular.small" gutter="medium">{subtitle}</Typo>
            {/* <Chip onClick={() => { }}
                variant="primary"
                leftIcon="Geoloc"
                size="medium"
                gutter="none">
                    Bientôt disponible</Chip> */}
        </ThemeCardContainer>
    )
});

export default ThemeCard;