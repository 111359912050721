import React, { useMemo, useEffect, memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "../../Carousel";
import Slide from "../../Carousel/Slide";
import { getJobSimilar } from "../../../redux/jobs/actions";
import { push } from "connected-react-router";
import { formatJobURL } from "../../../utils/urlSeo";
import { Dialog, Typo, Button, Chip, Divider } from "@ozlydev/ozly-sand-ui";
import { truncateString } from "../../../utils/strings";
import Like from "../../Like";
import JobBadges from "../Badges";

const CarouselSimilar = ({ jobId, type, showViewed }) => {
    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);
    const similarJobs = useSelector(state => state.jobs?.similar?.[jobId]?.[type] || null);

    useEffect(() => {
        dispatch(getJobSimilar(jobId, type, showViewed));
    }, [jobId, showViewed]);

    const slides = useMemo(() => {
        if (!similarJobs) {
          return [];
        }

        const toDisplay = showMore ? similarJobs : similarJobs.slice(0, 3);
  
        const elemts = toDisplay?.map((result, index) => (
          <Slide key={result._id} onClick={(e) => {
            // e.stopPropagation();
            // dispatch(push(formatJobURL(result)));
          }}>
            <Dialog 
            title=""
            subtitle=""
            onClick={
              (e) => {
                dispatch(push(formatJobURL(result)));
              }
            } key={result._id} style={{  margin: 'auto', padding: '32px 32px', position: 'relative', alignItems: 'inherit', backgroundColor: index % 2 === 0 ? 'rgb(0 0 0 / 2%)' : '#fff2e8', maxWidth: '100%', width: '100%', border: 'none', borderBottom: '1px solid #f8e1d4', borderRadius: 0}} flat
            >
              <div style={{ position: 'absolute', top: 24, right: 24 }}>
                <Like key={'like' + result._id} id={result._id} />
              </div>
              <Typo variant="paragraph.bold.large" gutter="none" style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                webkitLineClamp: '2',
                webkitBoxOrient: 'vertical',
              }}>
                {result.title}
              </Typo>
              <Typo variant="paragraph.regular.small" gutter="none">
                {result.company}
              </Typo>
              {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {result?.isForStudent?.['has_CONTRACT_HOUR_PARTTIME'] &&
                  <Chip leftIcon="Clock" gutter="small">temps-partiel</Chip>
                }
                {result?.metadata_flags?.['has_CONTRACT_HOUR_FULLTIME'] &&
                  <Chip leftIcon="Clock" gutter="small">temps-plein</Chip>
                }
              </div> */}
              <div>
                <Typo variant="paragraph.regular.small" gutter="none" style={{ marginTop: 16 , marginBottom: 16}}>
                  {truncateString(result.description, 200)}
                </Typo>
              </div>
              <JobBadges job={result} size="large" />
            </Dialog>
          </Slide>
        ));
    
        return elemts;
      }, [similarJobs, showMore]);

    if (!similarJobs || similarJobs?.length === 0) {
        return null;
    }
    
    return (
      // <Carousel
      //   extraSettings={{
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //   }}
      //   slides={slides}
      //   title="Offres similaires"
      //   subtitle="Découvrez les offres similaires à celle-ci"
      // />

      <div>
        {slides}
        <Button
          onClick={() => {
            setShowMore(!showMore);
          }}
          style={{ margin: 'auto', marginTop: 32, marginBottom: 32, display: 'flex' }}
          active
          size="medium">
          {showMore ? "Voir moins" : "Voir plus"}
        </Button>
      </div>
    );
  };

  CarouselSimilar.defaultProps = {
    type: 'default',
    showViewed: true,
  };

  export default memo(CarouselSimilar);