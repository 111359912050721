export const GET_USER_ALERTS = 'GET_USER_ALERTS';
export const GET_USER_ALERTS_SUCCESS = 'GET_USER_ALERTS_SUCCESS';
export const GET_USER_ALERTS_FAIL = 'GET_USER_ALERTS_FAIL';

export const ADD_USER_ALERT = 'ADD_USER_ALERT';
export const ADD_USER_ALERT_SUCCESS = 'ADD_USER_ALERT_SUCCESS';
export const ADD_USER_ALERT_FAIL = 'ADD_USER_ALERT_FAIL';

export const DELETE_USER_ALERT = 'DELETE_USER_ALERT';
export const DELETE_USER_ALERT_SUCCESS = 'DELETE_USER_ALERT_SUCCESS';
export const DELETE_USER_ALERT_FAIL = 'DELETE_USER_ALERT_FAIL';

export const ADD_KEEP_IN_TOUCH = 'ADD_KEEP_IN_TOUCH';
export const ADD_KEEP_IN_TOUCH_SUCCESS = 'ADD_KEEP_IN_TOUCH_SUCCESS';
export const ADD_KEEP_IN_TOUCH_FAIL = 'ADD_KEEP_IN_TOUCH_FAIL';