export const GET_JOB = 'GET_JOB';
export const GET_JOB_SUCCESS = 'GET_JOB_SUCCESS';
export const GET_JOB_FAIL = 'GET_JOB_FAIL';

export const SET_JOB_DATA = 'SET_JOB_DATA';

export const GET_NEXT_JOB = 'GET_NEXT_JOB';
export const GET_NEXT_JOB_DONE = 'GET_NEXT_JOB_DONE';
export const GET_PREVIOUS_JOB = 'GET_PREVIOUS_JOB';
export const GET_PREVIOUS_JOB_DONE = 'GET_PREVIOUS_JOB_DONE';

export const GET_JOB_SIMILAR = 'GET_JOB_SIMILAR';
export const GET_JOB_SIMILAR_SUCCESS = 'GET_JOB_SIMILAR_SUCCESS';
export const GET_JOB_SIMILAR_FAIL = 'GET_JOB_SIMILAR_FAIL';

export const GET_JOBS_BY_SEARCH_ID = 'GET_JOBS_BY_SEARCH_ID';
export const GET_JOBS_BY_SEARCH_ID_SUCCESS = 'GET_JOBS_BY_SEARCH_ID_SUCCESS';
export const GET_JOBS_BY_SEARCH_ID_FAIL = 'GET_JOBS_BY_SEARCH_ID_FAIL';
