import * as types from './types';

const initialState = {
};

const feedbacksReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        [action.feedbacks.topic]: {
          ...state[action.feedbacks.topic],
          [action.feedbacks.idTopic]: action.payload,
        },
      };

    case types.SET_FEEDBACK_SUCCESS:
      return {
        ...state,
        [action.feedbacks.topic]: {
          ...state[action.feedbacks.topic],
          [action.feedbacks.idTopic]: action.payload,
        },
      };


    default:
      return state;
  }
};

export default feedbacksReducer;
